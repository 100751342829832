/*font*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");

/*color*/
$white: #fff;
$gray: #f7f7f7;
$gray_more: #ddd;
$black: #111;
$black_soft: #444;
$black_col: #aaa;
$black_more: #090707;
$color: #ffb41d;
$color_more: #fdaf11;

html {
  overflow-x: hidden;
}
body {
  font-family: "Poppins", "Segoe UI", "Ubuntu", "Droid Sans", sans-serif;
  font-size: 0.98rem;
  font-weight: 300;
  letter-spacing: 0.3px;
  padding: 0;
  margin: 0;
  color: $white;
  background: #1b1b1b;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
.heading {
  font-weight: 600;
  color: $white;
}
section {
  padding: 75px 0;
}
.m-2-hor {
  padding: 0 5%;
  max-width: 1600px;
  margin: 0 auto !important;
}
.bggray {
  background: $gray;
  color: $black_col;
}
.bgblack {
  background: $gray;
  color: $black_soft;
}
.color {
  color: $color;
}
.heading {
  font-size: 2.1rem;
  line-height: 2.9rem;
  margin-bottom: 1rem;
}
.content {
  line-height: 1.6rem;
  margin-bottom: 1rem;
}
.link {
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    color: $color_more !important;
    opacity: 1 !important;
    transition: all 0.3s ease;
  }
}
.dflex-center {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.head {
  border-radius: 0;
  padding: 0;
  width: 100%;
  height: 82px;
  background: #1b1b1b;
  margin-bottom: 0;
  position: sticky;
  top: 0;
  left: 0;
}
.imgtop {
  position: relative;
  border-radius: 0;
  padding: 0;
  margin: 0;
  max-height: 60vh;
  overflow: hidden;
  background: none;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
@media (max-width: 640px) {
  .img-fluid {
    width: 64%;
  }
  .link {
    font-size: 90%;
  }
}

#preloader {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #222;
}
#preloader .text {
  position: relative;
  font-size: 1.6rem;
  top: 43%;
  margin: 0;
  margin-bottom: 50px;
  position: relative;
  display: block;
  opacity: 1;
  text-align: center;
  color: #fff;
  z-index: 9999;
}
.spinner {
  width: 40px;
  height: 40px;
  border: 2px solid #333;
  border-top: 2px solid #ffb81b;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: spin 1.2s infinite ease;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* totop */
#scroll-to-top {
  cursor: pointer;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 30px;
  z-index: 999;
  &.init {
    bottom: -60px;
    transition: all 0.3s ease;
  }
  &.show {
    bottom: 20px;
    transition: all 0.3s ease;
  }
  div {
    font-size: 0.8rem;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    color: $white;
    background: $black_soft;
    transition: all 0.3s ease;
    &:hover {
      background: $black;
      transition: all 0.3s ease;
    }
  }
}

#header {
  position: fixed;
  width: 100%;
  height: 82px;
  top: 0;
  left: 0;
  background: $black;
  opacity: 0;
  z-index: 99;
  transition: all 0.3s ease;
  &.sticky {
    background: $black;
    opacity: 1;
    transition: all 0.6s ease;
    .mainheader {
      position: relative;
      right: 15px;
      top: 15px;
      transition: all 0.3s ease;
    }
  }
}
.logo {
  position: fixed;
  top: 20px;
  left: 30px;
  z-index: 99;
  cursor: pointer;
  .callus {
    position: relative;
    top: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    color: $white;
    padding-left: 30px;
    margin-left: 45px;
    border-left: 3px solid rgba(255, 255, 255, 0.8);
  }
}
@media (max-width: 640px) {
  .callus {
    display: none;
  }
}
.mainBtn {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 5px;
  a {
    color: $white;
    transition: all 0.3s ease;
    &:hover {
      color: $color;
      transition: all 0.3s ease;
    }
  }
}
.midwrpr {
  position: relative;
  .info {
    display: block;
    padding-top: 30px;
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    span {
      color: $white;
      opacity: 0.7;
      display: block;
      margin-bottom: 5px;
    }
    .link {
      color: $white;
    }
  }
  .conPage {
    width: 300px;
    height: 250px;
    margin: auto 0;
    overflow-y: scroll;
  }
}
.soc-icon {
  position: absolute;
  bottom: 4%;
  left: 7%;
  z-index: 1;
  span {
    font-weight: 600;
    color: $white;
    opacity: 1;
    margin: 0 5px 5px 0;
  }
  .socicon {
    color: $white;
    opacity: 0.7;
    margin: 0px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $color;
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}

/* slider */
.jumbomain {
  position: relative;
  padding: 0;
  margin: 0;
}
.slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .previousButton,
  .nextButton {
    opacity: 0.5;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  &:hover {
    .previousButton,
    .nextButton {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}
.slide {
  background-size: cover !important;
}
.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
}
.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  text-align: center;
  svg {
    display: none;
  }
}
.previousButton::before,
.nextButton::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: normal;
  color: #fff;
}
.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}
.previousButton {
  left: 3%;
}
.nextButton {
  right: 3%;
}
.slider-content {
  text-align: left;
}
.slider-content .inner {
  padding: 0 7%;
  max-width: 1660px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.slider-content .inner button {
  font-weight: 600;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  letter-spacing: 1px;
  color: $white;
  border: 3px solid $color;
  background: none;
  border-radius: 0;
  padding: 0 44px;
  height: 45px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span {
    position: relative;
    z-index: 1;
  }
  &::before {
    content: "";
    width: 180%;
    height: 180%;
    top: -100%;
    left: -100%;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}
.shine {
  content: "";
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: -50px;
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  -webkit-transform: rotateZ(60deg) translate(-1em, 5.5em);
  transform: rotateZ(60deg) translate(-1em, 5.5em);
}
@-webkit-keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

@keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}
.slider-content .inner h1 {
  font-weight: 600;
  max-width: 840px;
  color: #fff;
  font-size: 60px;
}
.slider-content .inner p {
  font-weight: 600;
  color: #fff;
  font-size: 48px;
  line-height: 1.3;
  max-width: 640px;
  margin-bottom: 30px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.slider-content section span {
  color: #fff;
}
.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}
.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}
.slider-content section span strong {
  color: #fff;
  font-size: 14px;
  display: block;
}
@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}
@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}
@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
  .slider-content .inner p {
    font-size: 21px;
  }
}
.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}
.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}
.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}
.slide section * {
  transition: all 0.3s ease;
}
.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}
.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}
.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}
.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}
.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}
.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}
.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}
.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/*slick*/
.slick-slider {
  margin-right: -15px;
}
.slick {
  width: 100%;
  position: relative;
  padding: 30px 0 0;
  margin-bottom: -7px;
  .slick-prev {
    left: unset;
    right: 60px !important;
    top: -15%;
    z-index: 1;
    transition: all 0.3s ease;
    &::before {
      content: "\f053" !important;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $gray;
    }
  }

  .slick-dots li.slick-active button:before {
    color: $white;
  }
  .slick-dots li button:before {
    color: $gray;
  }

  .slick-next {
    top: -15%;
    right: 17px;
    transition: all 0.3s ease;
    &::before {
      content: "\f054" !important;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $gray;
    }
  }
  .slick-slide {
    position: relative;
    div {
      margin-right: 15px;
      .logos {
        width: 80%;
        height: 80%;
        margin-bottom: 8px;
      }
      .itm {
        margin: 0;
        position: relative;
        height: 100%;
        outline: none;
        overflow: hidden;

        .bg {
          position: relative;
          overflow: hidden;
          margin-right: 0px;
          img {
            width: 100%;
            height: 100%;
            min-height: 380px;
            object-fit: cover;
          }

          &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 1),
              rgba(0, 0, 0, 0.6)
            );
            opacity: 0.3;
            transition: all 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
        .desc {
          position: absolute;
          bottom: 15px;
          left: 25px;
          text-align: left;
          color: $white;
          .name {
            font-weight: 500;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
            padding-bottom: 20px;
          }
          .content {
            position: relative;
            bottom: -100%;
            color: $gray_more;
            margin-bottom: -40%;
            opacity: 0;
            transition: all 0.6s ease;
          }
          .title {
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1.5;
            margin-top: -15px;
            transition: all 0.3s ease;
          }
          .date {
            font-size: 0.8rem;
            font-weight: 400;
            letter-spacing: 0.6px;
            padding-bottom: 0px;
            color: $gray;
          }
        }
        .icon {
          width: 100%;
          position: absolute;
          text-align: right;
          right: 15px;
          bottom: -30px;
          transition: all 0.3s ease;
          span {
            width: 100%;
            height: auto;
            color: $gray_more;
            padding: 10px;
            i {
              transition: all 0.3s ease;
            }
            &:hover {
              i {
                color: $color;
              }
            }
          }
        }
        &:hover {
          cursor: pointer;
          .bg:before {
            opacity: 1;
            height: 100%;
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 1),
              rgba(0, 0, 0, 0.6)
            );
            transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          .icon {
            bottom: 20px;
            transition: all 0.3s ease;
          }
          .content {
            opacity: 1;
            margin-bottom: 30px;
            transition: all 0.9s ease;
          }
          .title {
            line-height: 0.9;
            transition: all 0.3s ease;
          }
        }
      }
    }
    &.slick-col {
      display: block;
      overflow: hidden;
      div .itm .bg img {
        min-height: 300px !important;
      }
    }
  }
  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
  &.slickclient {
    padding: 0;
    .slick-slider {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
      .itm {
        img {
          margin: 0 auto;
        }
      }
    }
  }
  &.slickproject {
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    div {
      margin-right: 0px;
      .itm {
        img {
          margin: 0 auto;
        }
        .desc {
          position: absolute;
          bottom: 15px;
          left: 25px;
          text-align: left;
          color: $white;
          .tag {
            font-size: 0.9rem;
            padding-bottom: 10px;
          }
          .name {
            max-width: 180px;
            font-weight: 500;
            font-size: 1.8rem;
            letter-spacing: 0.6px;
            padding-bottom: 35px;
            transition: all 0.3s ease;
          }
        }
        .icon {
          opacity: 0;
          left: 25px;
          bottom: 10px;
          text-align: left;
          transition: all 0.3s ease;
          span {
            display: inline-block;
            white-space: nowrap;
            width: 40px;
            padding: 0 0 5px 0;
            color: $gray_more;
            border-bottom: 2px solid $color;
            transition: all 0.3s ease;
            &:hover {
              color: $white;
              width: 100px;
              transition: all 0.3s ease;
            }
          }
        }
        &:hover {
          cursor: pointer;
          .icon {
            opacity: 1;
            bottom: 25px;
            transition: all 0.3s ease;
          }
          .name {
            padding-bottom: 45px;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
  &.slicknews {
    .slick-next,
    .slick-prev {
      top: -12%;
    }
    .slick-slide {
      div {
        margin-right: 15px;
        .bg {
          margin-right: 0;
        }
        .itm {
          .desc {
            bottom: 60px;
            .content {
              margin-bottom: -30%;
            }
          }
          .icon {
            bottom: 5px;
            left: 0;
            padding: 10px 26px;
            text-align: left;
            right: unset;
            span {
              display: inline-block;
              white-space: nowrap;
              width: 40px;
              padding: 0 0 5px 0;
              color: $gray_more;
              border-bottom: 2px solid $color;
              transition: all 0.3s ease;
              &:hover {
                color: $white;
                width: 85px;
                transition: all 0.3s ease;
              }
            }
          }
          &:hover {
            .icon {
              bottom: 0;
              padding: 15px 26px;
            }
            .content {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

/* home */
.featurehome {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  .img {
    font-size: 2.3rem;
    color: $color_more;
    width: 40%;
    text-align: center;
  }
  .text {
    font-size: 1.1rem;
    line-height: 21px;
    font-weight: 400;
    color: $black;
  }
  &.last {
    border-right: none;
  }
}
.col-home {
  .thumb {
    position: relative;
    width: 100%;
    /*margin: 0 auto;*/
    img {
      height: auto;
      max-width: 80%;
      vertical-align: middle;
    }
    &::before {
      content: "";
      width: 80%;
      height: 100%;
      position: absolute;
      bottom: -10%;
      right: 10%;
      border: 10px solid $gray_more;
      @media only screen and (max-width: 500px) {
        bottom: -20%;
        width: 70%;
        right: 20%;
        border: 6px solid $gray_more;
      }
    }
  }
  .list-home {
    list-style: none;
    padding: 0;
    li {
      font-size: 1.1rem;
      font-weight: 400;
      width: 100%;
      padding: 4px 0;
      margin-bottom: 10px;
      &::before {
        font-size: 0.8rem;
        content: "\f054" !important;
        font-family: FontAwesome !important;
        font-style: normal;
        font-weight: normal;
        color: $color_more;
        margin-right: 15px;
      }
    }
  }
}
#listcomplish {
  position: relative;
  .linebg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.04;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .listcomplish {
    text-align: left;
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
    .year {
      width: 30%;
      font-size: 1.3rem;
      font-weight: 600;
      margin-right: 15px;
    }
    @media only screen and (max-width: 800px) {
      .year {
        font-size: 0.9rem;
      }
    }
    .content {
      width: 40%;
      font-weight: 600;
      margin-bottom: 0;
      margin-right: 15px;
    }
    .detail {
      text-align: center;
      width: 30%;
      font-weight: 400;
      //color: $gray;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

/* footer */
footer {
  color: $white;
  background: $black;
  position: relative;
  padding: 75px 0 0;
  padding-right: 0px !important;
  padding-left: 0px !important;
  overflow: hidden;
  .bg-footer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .footer-col {
    padding-right: 15px;
    padding-left: 15px;
    margin: 15px 0;
    .heading {
      color: $white;
      font-weight: 500;
      font-size: 1.1rem;
      margin-bottom: 10px;
      h2 {
        color: $white;
      }
    }
    .content {
      line-height: 1.8;
      letter-spacing: 0.6px;
      margin-bottom: 10px;
      p {
        font-size: 0.93rem;
      }
      .link {
        font-size: 0.9rem;
        width: max-content;
        letter-spacing: 0;
        padding: 0 0 5px;
        margin-bottom: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          opacity: 0.6;
          transition: all 0.3s ease;
        }
      }
      .socialicon {
        position: relative;
        overflow: hidden;
        display: inline-block;
        padding: 10px 14px 6px;
        background: none;
        margin-right: 10px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.1);
        transition: all 0.3s ease;
        i {
          font-size: 1.3rem;
        }
        &:hover {
          background: $color;
          transition: all 0.3s ease;
          .shine {
            z-index: 1;
            -webkit-animation: sheen 2s alternate;
            animation: sheen 2s alternate;
          }
        }
      }
    }
    .link-call {
      font-weight: 300;
      display: inline-block;
      color: $color;
      font-size: 0.8rem;
      width: max-content;
      letter-spacing: 1px;
      padding: 0 0 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.6;
        transition: all 0.3s ease;
      }
    }
  }
}
.subfooter {
  width: 100%;
  margin-top: 90px;
  padding: 30px 15px;
  background: rgba(0, 0, 0, 0.6);
  .content {
    font-size: 0.8rem;
    opacity: 1;
    letter-spacing: 0.3px;
    margin: 0;
    color: #eee;
  }
  .content-right {
    text-align: right;
    font-size: 0.9rem;
    opacity: 0.8;
    letter-spacing: 0.3px;
    margin: 0;
    i {
      padding: 0 5px;
    }
  }
  @media only screen and (max-width: 768px) {
    .content-right {
      text-align: left;
      margin-top: 5px;
    }
  }
}

/* works */
.herocaption {
  padding: 0px 7% 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
}
.backdetail {
  white-space: nowrap;
  top: 80px;
  left: 0px;
  span {
    color: $white;
    padding-left: 45px;
    font-size: 1.3rem;
    font-weight: 600;
  }
}
#detailproject {
  display: block;
  .content {
    padding-bottom: 15px;
  }
  .sticky {
    position: sticky;
    top: 0;
    left: 0;
    .detailproject {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: $white;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        padding: 0 0 15px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .tile {
          font-weight: 600;
        }
      }
    }
  }
}

.pagination {
  padding: 90px 0;
  li {
    .page-link {
      color: $white;
      background: $black;
      border: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      padding: 0.7rem 1.15rem;
      transition: all 0.3s ease;
      i {
        color: $white;
        position: relative;
        top: 1px;
      }
      &:hover {
        color: $white;
        background: $black_soft;
        transition: all 0.3s ease;
        i {
          color: $white;
        }
      }
      &.active {
        color: $white;
        background: $black_soft;
      }
    }
  }
}
.single-img {
  display: inline-block;
  width: 100%;
  height: auto;
  overflow: hidden;
  &.max-height400 {
    max-height: 400px;
  }
  &.max-height300 {
    max-height: 297px;
  }
  &.pimg-5 {
    padding: 15px;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.tags {
  display: block;
  margin: 15px 0;
  .heading {
    font-size: 0.98rem;
    font-weight: 600;
    margin-right: 15px;
  }
  .content {
    font-size: 0.96rem;
    font-weight: 300;
    color: $black_col;
    margin-right: 5px;
    &::after {
      content: ",";
    }
    &:last-child {
      &::after {
        content: "";
      }
    }
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $color;
      transition: all 0.3s ease;
    }
  }
}
.share {
  display: block;
  margin: 0 0 15px;
  .heading {
    font-size: 0.98rem;
    font-weight: 600;
    margin-right: 15px;
  }
  .content {
    font-size: 1rem;
    font-weight: 300;
    color: $black_col;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $color;
      transition: all 0.3s ease;
    }
  }
}

/** contact **/
.form-side {
  padding: 30px 40px;
  background: none;
  border-radius: 0px;
}
.text-side {
  padding: 0;
  margin-top: 0;
  .heading {
    font-size: 1.6rem !important;
  }
  .address {
    padding-top: 15px;
    line-height: 1.3;
    .heading {
      font-size: 1.3rem !important;
      font-weight: 500;
      letter-spacing: 1px;
    }
    .list {
      position: relative;
      max-width: 320px;
      display: block;
      padding: 5px 0 15px;
      padding-left: 40px;
      i {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 1.3rem;
        display: inline-block;
        color: $color;
      }
      a {
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
.formcontact {
  display: block;
  width: 100%;
  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    font-size: 0.9rem;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ebebeb;
    margin-bottom: 15px;
  }
  textarea {
    font-size: 0.9rem;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ebebeb;
    margin-bottom: 15px;
  }
  .msg-error {
    font-size: 0.87rem;
    opacity: 0.8;
    padding: 5px 0;
  }
  button {
    position: relative;
    overflow: hidden;
    font-size: 11pt;
    font-weight: 500;
    letter-spacing: 1px;
    color: $white;
    background: none;
    border: 2px solid $color;
    border-radius: 0;
    padding: 8px 50px;
    margin-top: 15px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 0;
    span {
      color: $white;
      position: relative;
      z-index: 3;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-image: linear-gradient(to left, $color, $color_more);
      opacity: 0;
      z-index: 1;
      transition: all 0.3s ease;
    }
    &:hover::before {
      opacity: 1;
      transition: all 0.3s ease;
    }
    &:hover {
      .shine {
        -webkit-animation: sheen 1s alternate;
        animation: sheen 1s alternate;
      }
    }
  }
}
#success,
#failed {
  &.show {
    display: block !important;
  }
  &.hide {
    display: none;
  }
}
#buttonsent.show {
  pointer-events: none;
}
.map {
  margin-bottom: 60px;
}
/*** media all ***/
@media only screen and (max-width: 1199px) {
}
@media only screen and (max-width: 992px) {
  .featurehome {
    border-right: 0;
    display: inline-block;
    .img,
    .text {
      display: inline-block;
      width: max-content;
    }
    .text {
      position: relative;
      top: -7px;
      left: 15px;
    }
  }
  section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .slick.slicknews .slick-slide div .itm .desc .content,
  .slick .slick-slide div .itm .content {
    margin-bottom: 10px !important;
    opacity: 1 !important;
    bottom: 15px !important;
  }
  .slick .slick-slide div .itm .bg::before {
    opacity: 0.3;
  }
  .slick.slickproject div .itm .icon {
    opacity: 1;
    bottom: 15px;
  }
  #detailproject {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .herocaption {
    h1 {
      font-size: 2rem !important;
    }
  }
}
